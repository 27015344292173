import React, { useState } from "react";
import { Pop } from './lib/Pop'
import './index.less'

interface IProps{
    children:any
    content: any
    parentElement?: any
    show?:any
}


const Popover = (props:IProps)=>{
    const {children, content, parentElement,show} = props;
    const [visible, setVisible] = useState(false)

    const renderContent = () => {
        return (
            <>
                {content && <div className="popoverwrap">{show||content}</div> || ''}
            </>
        )
    }
    return (
        <Pop isOpen={visible} content={renderContent} parentElement={parentElement} containerStyle={{zIndex: '9999'}}>
            <div style={{height: '100%'}} onClick={() => setVisible(true)} onMouseOver={() => setVisible(true)}
                 onMouseLeave={() => setVisible(false)}>
                {children}
            </div>
        </Pop>
    )
}

export default Popover;


